/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';

// App
import SyncItemFormSummaryItem from 'modules/Item/components/SyncItemFormSummaryItem';

const Container = Styled.View`
`;

const Item = Styled.View`
  flex: 1;
`;

const SyncItemFormSummariesList = ({
  listKey,
  kind,
  syncItemForms,
}: {
  listKey: string;
  kind: string;
  syncItemForms: any[];
}) => {
  const numberOfColumns = 2;
  const numberOfFillerItems = numberOfColumns - (syncItemForms.length % numberOfColumns);
  const fillerItems = _.fill(Array(numberOfFillerItems), {});
  const allSyncItemForms = _.concat(syncItemForms, fillerItems);

  return (
    <Container>
      <FlatList
        listKey={listKey}
        numColumns={numberOfColumns}
        data={allSyncItemForms}
        keyExtractor={(syncItemForm, index) => String(index)}
        renderItem={({item: syncItemForm}) => {
          if (_.isEmpty(syncItemForm)) {
            return <Item />;
          }
          return (
            <Item>
              <SyncItemFormSummaryItem kind={kind} syncItemForm={syncItemForm} />
            </Item>
          );
        }}
      />
    </Container>
  );
};

export default SyncItemFormSummariesList;
